// This must be the exact same as in ui-bibz-js
//import bootstrap from 'ui-bibz-js/node_modules/bootstrap/dist/js/bootstrap.bundle'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'
import Modal from './lib/modal.js'
import { Selection } from './selection.js'
import * as Sentry from '@sentry/browser'

export default class BaseActions {

  constructor(controller, items, context, currentItem){
    this.enabled_list = {}

    this.context = context

    let wrapControllerFunc = (fName) => {
      return ((actions) => {
        if (!controller[fName]) return
        if (this.debug && this.actStatus) this.actStatus.push(fName)
        controller[fName].bind(controller)(actions)
      })
    }

    this.controller = controller
    this._enableToolbar = wrapControllerFunc('_enableBtns')
    this._enableContext = wrapControllerFunc('_enableMenuItems')
    this._disableToolbar = wrapControllerFunc('_disableBtns')
    this._disableContext = wrapControllerFunc('_disableMenuItems')
    this._showToolbar = wrapControllerFunc('_showBtns')
    this._showContext = wrapControllerFunc('_showMenuItems')
    this._hideToolbar = wrapControllerFunc('_hideBtns')
    this._hideContext = wrapControllerFunc('_hideMenuItems')

    this.updateItems(items, currentItem)

    this.disposePopoverOnClick = this.disposePopoverOnClick.bind(this)
    window.addEventListener('click', this.disposePopoverOnClick, true)
    window.addEventListener('contextmenu', this.disposePopoverOnClick, true)

    this.debug = false
  }

  disposePopoverOnClick(e){
    if (this.popover && !e.target.closest(`#${this.popover.tip.id}`)) {
      e.preventDefault()
      e.stopPropagation()
      this.popover.dispose()
      this.popover = undefined
    }
  }

  updateItems(givenItems, givenCurrentItem){
    if (givenItems) this.savedItems = givenItems
    if (givenCurrentItem) this.savedCurrentItem = givenCurrentItem

    let items = givenItems || this.savedItems
    let currentItem = givenCurrentItem || this.savedCurrentItem

    if (this.debug) console.log('updateItems(%o, %o)', items, currentItem)

    this.updateRole()

    if (typeof(items) == 'object' && items.length === undefined) {
      for (let itname in items) this.updateSpecificItems(itname, items[itname], currentItem)
      this.updateNodeItems(items.node || [], 'node', currentItem)
    } else {
      this.updateNodeItems(items || [], 'node', currentItem)
    }
  }

  updateActions(actions){
    if (this.debug) console.log('updateActions(actions = %o, this.actions = %o)', actions, this.actions)
    if (actions) this.actions = actions

    this.update()
  }

  update(){
    if (!this.actions || !this.items) return
    this.selection = new Selection()

    if (this.debug) console.log('BaseActions::update(actions = %o, items = %o)', this.actions, this.items)
    for (let actname in this.actions){
      try {
        let act = this.actions[actname]
        if (act) {
          if (this.debug) this.actStatus = []
          this.debugOnce = false
          const bareactname = actname.replace(/Target$/, '')
          if (this[`${bareactname}Status`]) {
            const act_status = this[`${bareactname}Status`](this.context)
            const enabled = (typeof(act_status) == 'boolean') ?
              act_status :
              act_status.enabled
            const visible = (act_status.visible === undefined) ?
              (enabled || this.context.toolbar) :
              act_status.visible
            if (this.context.menu) {
              if (!visible) {
                this._hideContext([act])
              } else {
                this._showContext([act])
                if (enabled) this._enableContext([act])
                else this._disableContext([act])
              }
            } else if (this.context.toolbar) {
              if (!visible) {
                this._hideToolbar([act])
              } else {
                this._showToolbar([act])
                if (enabled) this._enableToolbar([act])
                else this._disableToolbar([act])
              }
            }
          } else {
            this.updateAction(bareactname, act)
          }
          if (this.debug) console.log('Update action %s : %o', actname, this.actStatus?.join(', '))
        }
      } catch (err) {
        console.error(err)
        Sentry.captureException(err)
      }
    }
    if (this.controller._hideEmptyMenu) this.controller._hideEmptyMenu()
  }

  // Protected

  updateRole() {
    this.role_team_manager = JSON.parse(document.body.dataset.lcasRoleTeamManager || 'false')
    this.role_database_manager = JSON.parse(document.body.dataset.lcasRoleDatabaseManager || 'false')
    this.role_admin = JSON.parse(document.body.dataset.lcasRoleAdmin || 'false')
    this.role_true_admin = JSON.parse(document.body.dataset.lcasRoleTrueAdmin || 'false')
  }

  is_owner(_controls) {
    const controls = (_controls.length == undefined) ? [_controls] : Array.from(_controls)
    return controls.map(c => JSON.parse(c.dataset.owner)).reduce((a,b) => (a && b), controls.length > 0)
  }

  is_writable(_controls) {
    const controls = (_controls.length == undefined) ? [_controls] : Array.from(_controls)
    return controls.map(c => JSON.parse(c.dataset.writable)).reduce((a,b) => (a && b), controls.length > 0)
  }

  updateNodeItems(items, _itname, currentItem){
    this.currentItem = currentItem ||
                       document.querySelector('.c-item--current') ||
                       document.querySelector('.c-treeview__label--current') ||
                       document.querySelector('.c-treeview__item--current > label') ||
                       document.querySelector('label.current')
    if (this.currentItem && this.currentItem.control) this.currentItem = this.currentItem.control
    this.currentWritable = this.currentItem ? JSON.parse(this.currentItem.dataset.writable || 'false') : false
    this.currentOwner = this.currentItem ? JSON.parse(this.currentItem.dataset.owner || 'false') : false

    if (items.length == 0 && this.currentItem) items = [this.currentItem]

    this.items = Array.from(items)
    this.itemsRefId = Array.from(items, i => i.value)
    this.itemsWritable = Array.from(items, i => JSON.parse(i.dataset.writable || 'false'))
      .reduce((a, b) => (a && b), true)
    this.itemsOwner = Array.from(items, i => JSON.parse(i.dataset.owner || 'false'))
      .reduce((a, b) => (a && b), true)
    this.itemsType = Array.from(items, i => i.dataset.type)
      .reduce((a, b) => ((a === null) ? b : (a == b) ? a : false), null)

    this.writable = this.itemsWritable
    this.owner = this.itemsOwner

    this.rootItem = document.querySelector('.c-treeview__label--root')

    this.item = null
    if (this.items.length == 1) this.item = this.items[0]

    if (this.item === null) {
      this.itemWritable = undefined
      this.itemOwner = undefined
      this.itemType = undefined
    } else {
      this.itemWritable = JSON.parse(this.item.dataset.writable || 'false')
      this.itemOwner = JSON.parse(this.item.dataset.owner || 'false')
      this.itemType = this.item.dataset.type
    }

    this.update()
  }

  updateSpecificItems(itname, items, currentItem) {
    let items_array = Array.from(items)
    this[`${itname}s`] = items_array
    this[itname] = items.length == 1 ? items[0] : null

    if (this[`update${_.titleize(itname)}Items`]) {
      return this[`update${_.titleize(itname)}Items`](items_array, itname, currentItem)
    }
  }

  updateAction(actname, action) {
    console.error(`Unknown action ${actname}`)
  }

  is_enabled(action) {
    return this.enabled_list[action]
  }

  // enable(action, enableToolbar=true, enableContext=enableToolbar)
  enable(_actions, _enableToolbar, _enableContext){
    let actions = (_actions.length == undefined) ? [_actions] : Array.from(_actions)
    let enableToolbar = (_enableToolbar == undefined) ? true : _enableToolbar
    let enableContext = (_enableContext == undefined) ? enableToolbar : _enableContext

    if (this.debugOnce) console.log('Action#enable(toolbar=%o, context=%o) for %o', enableToolbar, enableContext, actions)

    for (let act of actions) {
      const actName = Object.keys(this.actions).find(n => this.actions[n] === act)
      this.enabled_list[actName] = enableToolbar || enableContext
    }

    if (enableToolbar) {
      this._enableToolbar(actions)
    } else {
      this._disableToolbar(actions)
    }
    if (enableContext) {
      this._enableContext(actions)
    } else {
      this._disableContext(actions)
    }
  }

  disable(actions, disable) {
    this.enable(actions, disable == undefined ? false : !disable)
  }

  // show(actions, showToolbar=true, showContext=showToolbar, enableToolbar=undefined, enableContext=undefined)
  // show(actions, showToolbar=true, showContext=showToolbar, enableBoth)
  //
  // Will show the item depending on the context, and optionally enable it:
  // - if showToolbar is true, it will be shown in the toolbar
  // - if showToolbar && showContext is true, it will be shown in the context
  //   menu
  // - if enableToolbar is defined but enableContext is undefined, then items
  //   from both toolbar and context menu will be enabled if
  //   (showToolbar && showContext && enableToolbar) is true
  // - if enableToolbar is defined and enableContext is defined, the toolbar
  //   item will be enabled if (showToolbar && enableToolbar) is true and the
  //   context menu items will be enabled if
  //   (showToolbar && showContext && enableContext) is true
  show(_actions, _showToolbar, _showContext, _enableToolbar, _enableContext){
    let actions = (_actions.length == undefined) ? [_actions] : Array.from(_actions)
    const showToolbar = (_showToolbar == undefined) ? true : _showToolbar
    const showContext = (_showContext == undefined) ? showToolbar : (showToolbar && _showContext)
    const isEnableBoth = _enableToolbar != undefined && _enableContext == undefined
    const enableBoth = showToolbar && showContext && _enableToolbar
    const enableToolbar = showToolbar && _enableToolbar
    const enableContext = showToolbar && showContext && _enableContext

    if (showToolbar) {
      this._showToolbar(actions)
    } else {
      this._hideToolbar(actions)
    }

    if (showContext) {
      this._showContext(actions)
    } else {
      this._hideContext(actions)
    }

    if (isEnableBoth){
      if (this.debugOnce) {
        console.log('Action#show(toolbar=%o, context=%o, enable=%o) for %o',
          showToolbar, showContext, enableBoth, actions)
      }

      for (let act of actions) {
        const actName = Object.keys(this.actions).find(n => this.actions[n] === act)
        this.enabled_list[actName] = enableBoth && (showToolbar || showContext)
      }

      if (enableBoth) {
        if (showToolbar) this._enableToolbar(actions)
        if (showContext) this._enableContext(actions)
      } else {
        if (showToolbar) this._disableToolbar(actions)
        if (showContext) this._disableContext(actions)
      }
    } else {
      if (this.debugOnce) {
        console.log('Action#show(toolbar=%o, context=%o, enable-toolbar=%o, enable-context=%o) for %o',
          showToolbar, showContext, enableToolbar, enableContext, actions)
      }

      for (let act of actions) {
        const actName = Object.keys(this.actions).find(n => this.actions[n] === act)
        this.enabled_list[actName] = _enableToolbar && showToolbar || _enableContext && showContext
      }

      if (_enableToolbar != undefined && showToolbar){
        if (enableToolbar) {
          this._enableToolbar(actions)
        } else {
          this._disableToolbar(actions)
        }
      }
      if (_enableContext != undefined && showContext){
        if (enableContext) {
          this._enableContext(actions)
        } else {
          this._disableContext(actions)
        }
      }
    }
  }

  showEnable(action, show, enable) {
    return this.show(action, show, enable, enable)
  }

  reinitializeItem(context) {
    this.submitHiddenForm(Routes.reinitialize_make_cs_items_path({item_ids: [this.item.value]}))
  }

  openForm(url, title = document.title){
    if (url.match(/\?/)) {
      url = `${url}&popup=true`
    } else {
      url = `${url}?popup=true`
    }
    const height = window.innerHeight * 0.75
    const width = window.innerWidth * 0.75
    document.dispatchEvent(new CustomEvent('form-open'))
    const win = window.open(url, '_blank', [
      `height=${height}`,
      `width=${width}`,
      'toolbar=no,location=no,directories=no,personalbar=no',
      'resizable=yes,scrollbars=no,dependent=no,modal=no,dialog=no',
    ].join(','))
    win.resizeTo(width, height)
  }

  goTo(url){
    window.location.href = url
  }

  blankGoTo(url, target0){
    let target = target0 || '_blank'
    let a = document.createElement('a')
    a.setAttribute('href', url)
    a.setAttribute('target', target)
    document.body.appendChild(a)
    a.click()
    a.remove()
  }

  submitHiddenForm(url, method, values){
    if (!values) values = {}
    if (!method) method = 'POST'
    if (!['GET', 'POST'].includes(method.toUpperCase())) {
      values['_method'] = method
      method = 'POST'
    }

    values['authenticity_token'] = document.querySelector('meta[name="csrf-token"]').content

    let form = document.createElement('form')
    form.style.display = 'none'
    form.setAttribute('action', url)
    form.setAttribute('method', method)
    for (let key in values) {
      const value = values[key]
      for (let v of ((typeof(value) != 'string' && value.length) ? value : [value])){
        const input = document.createElement('input')
        input.setAttribute('type', 'hidden')
        input.setAttribute('name', key)
        input.setAttribute('value', v)
        form.appendChild(input)
      }
    }
    document.body.appendChild(form)
    form.submit()
  }

  getPopoverPlacement(element) {
    let rect = element.getBoundingClientRect()
    let h = window.innerHeight
    let w = window.innerWidth
    if (rect.right < w/2) return 'right'
    if (rect.bottom < h/2) return 'bottom'
    if (rect.top >= h/2) return 'top'
    if (rect.left >= w/2) return 'left'
    return 'auto'
  }

  helperEditLine(element, options) {
    if (this.popover) this.popover.dispose()
    let label = this.item.labels[0]
    let item_id = this.item.value
    let old_name = label.dataset.name
    this.popover = new bootstrap.Popover(label, {
      title: options.title,
      placement: this.getPopoverPlacement(element),
      trigger: 'manual',
      sanitize: false,
      html: true,
      boundary: 'window',
      customClass: 'c-edit-line-popover',
      content: () => {
        if (options.frame_url) {
          let frame = document.createElement('turbo-frame')
          frame.setAttribute('src', options.frame_url)
          frame.setAttribute('id', options.frame_id)
          return frame.outerHTML
        } else {
          let frag = options.template.content.cloneNode(true)
          if (options.action) frag.querySelector('form').setAttribute('action', options.action)
          frag.querySelector('input[name=authenticity_token]').setAttribute('value',
            document.head.querySelector('meta[name=csrf-token]').content)
          options.onOpen(frag)
          return Array.from(frag.children, e => e.outerHTML).join('')
        }
      }
    })
    this.popover.show()
  }

  async runBackgroundAction(method, url, opts, headers) {
    let csrf_token = document.querySelector('meta[name="csrf-token"]').content
    const csrf_param = (url.includes('?') ? '&' : '?') + 'authenticity_token=#{escape(csrf_token)}'
    const response = fetch(url + csrf_param, {
      method: method,
      headers: new Headers({ 'x-csrf-token': csrf_token, ...headers }),
      ...opts
    })

    if (response.status < 200 || response.status >= 400) {
      const text = await response.text()
      throw new Error(text)
    }

    return response
  }

  addPageNotif(type, glyph, text, closeTimeout) {
    let container = document.querySelector('body > .notifyContainer')
    if (!container) {
      container = document.createElement('div')
      container.classList.add('notify-container')
      document.body.insertBefore(container, document.body.firstChild)
    }

    const span = document.createElement('span')
    span.textContent = text

    const notif = document.createElement('div')
    notif.classList.add(`alert-${type || 'info'}`, 'notify', 'alert', 'alert-dismissible')
    notif.dataset.timeout = 9000
    notif.setAttribute('role', 'alert')
    notif.innerHTML = `
      <i class="glyph fas fa-${glyph || 'check-circle'}"></i>
      ${span.innerHTML}
      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
    `

    container.insertBefore(notif, null)

    if (closeTimeout) setTimeout(() => {
      notif.classList.add('fade')
      setTimeout(() => notif.remove(), 1000)
    }, closeTimeout === true ? 9000 : closeTimeout)
  }

  async _deleteItemImplem(context, values, filterCb){
    let csrf_token = document.querySelector('meta[name="csrf-token"]').content
    const loader = new Modal({loader: true})
    const enabling_loader = loader.enable()
    try {
      const res = await fetch(this._buildMultiUrl(values, 'multi_destroy_message'))
      const json = await res.json()
      let ask_confirm = true
      await enabling_loader // ensure the loader is fully shown before hiding it
      await loader.disable()
      if (filterCb) {
        const res = await filterCb(json)
        if (res === true) ask_confirm = false
        if (res === false) return
      }

      if (json.error) {
        const m = new Modal({text: json.message, okText: I18n.t('ok')})
        m.enable()
        return
      }
      if (ask_confirm) {
        const m = new Modal({text: json.message, confirm: true, okText: I18n.t('delete')})
        m.btnOk.classList.add('btn-danger')
        m.modalElement.addEventListener('shown.bs.modal', () => m.btnOk.focus())
        await m.enable()
        const resp = await m.interacted()
        if (!resp) return
      }
      await loader.enable()

      let delete_response = await fetch(this._buildMultiUrl(values, 'multi_destroy'), {
        method: 'POST',
        body: new URLSearchParams({
          _method: 'DELETE',
          authenticity_token: csrf_token
        }),
        headers: {
          'x-csrf-token': csrf_token
        }})
        .then(resp => resp.json())
        .then(json => {
          // See #2706
          if (json.force_redirect) {
            window.location.href = json.url
          }
        })

    } catch (err) {
      console.error(err)
      throw err
    } finally {
      await loader.disable()
    }
  }

  _buildMakeUrl(type, id, action){
    let parentRefId = this.currentItem ? (this.currentItem.dataset.id || this.currentItem.value) : this.item.dataset.parentRefId
    let root_ref_id = document.body.dataset.rootId || this.rootItem.dataset.refId
    let query = `?interface=${document.body.dataset.interface}` +
                      (root_ref_id == null ? '' : `&root_ref_id=${root_ref_id}`) +
                      (parentRefId == null ? '' : `&parent_ref_id=${parentRefId}`)
    let url = _.compact(['/make', _.pluralize(type), id, action, query]).join('/')

    this.addTurboBlacklist(url)

    return url
  }

  _buildLibraryUrl(type, id, action){
    let root_ref_id = document.body.dataset.rootId || this.rootItem.dataset.refId
    let parentRefId = this.currentItem ? (this.currentItem.dataset.id || this.currentItem.value) : this.item.dataset.parentRefId
    let query = `?interface=${document.body.dataset.interface}` +
                      (root_ref_id == null ? '' : `&root_ref_id=${root_ref_id}`) +
                      (parentRefId == null ? '' : `&parent_ref_id=${parentRefId}`)
    let url = _.compact(['/case-studies', root_ref_id, 'design', _.pluralize(type), id, action, query]).join('/')

    this.addTurboBlacklist(url)

    return url
  }

  _buildMultiUrl(values, action, parentRef){
    let parentRefId = this.currentItem ? (this.currentItem.dataset.id || this.currentItem.value) : this.item.dataset.parentRefId
    let currentViewItemRefId = this.currentItem ? this.currentItem.dataset.id : null
    let itemsIds = '?' + values.map(x => `item_ids%5B%5D=${ x }`).join('&')
    let root_ref_id = document.body.dataset.rootId || this.rootItem.dataset.refId
    let query = `&interface=${document.body.dataset.interface}` +
                      (root_ref_id == null ? '' : `&root_ref_id=${root_ref_id}`) +
                      (parentRefId == null ? '' : `&parent_ref_id=${parentRefId}`) +
                      (currentViewItemRefId == null ? '' : `&current_view_item_id=${currentViewItemRefId}`)
    let url = _.compact(['/make/cs_items', action + itemsIds + query]).join('/')

    this.addTurboBlacklist(url)

    return url
  }

  _buildAdminMultiUrl(type, values, action){
    let itemsIds = '?' + values.map(x => `${type}_ids%5B%5D=${ x }`).join('&')
    let url = _.compact(['/admin',_.pluralize(type), action + itemsIds]).join('/')
    this.addTurboBlacklist(url)
    return url
  }

  _buildAdminUrl(type, id, action){
    let url = _.compact(['/admin', _.pluralize(type), id, action]).join('/')
    this.addTurboBlacklist(url)
    return url
  }

  addTurboBlacklist(url){
    if (window.lcas_debug?.includes('turbo-blacklist')) console.log('Add %o to turbo blacklist', url)
    window.turboBlacklist.push(url)
  }

  getSelectedItems(name){
    return document.querySelectorAll(`input[name='${name || 'node'}']:checked`)
  }

  copyRefId(){
    if (this.items.length > 1){
      navigator.clipboard.writeText(`['${Array.from(this.items, e => e.value).join("', '")}']`)
    } else
      navigator.clipboard.writeText(`"${this.item.value}"`)
  }

}
