import ApplicationController from '../application_controller'

/**
 * data-controller="frame-persist-query"
 * =====================================
 *
 * Attaches to a turbo-frame element and allows navigation applying to this
 * frame to keep query string parameters. Used for example in the design
 * overview to keep the tracking parameter.
 *
 * For example, the frame is showing URL `/document?a=1&b=2` and this controller
 * is configured to persist parameter `a`. When clicking on a link that
 * navigates on this frame to `/document2` then the final URL that is going to
 * be shown in the frame will be `/document2?a=1` (to keep parameter `a`).
 *
 * - `data-frame-persist-query-params-value=`: the list of parameters (space
 *   separated) to persist on the URL
 *
 * - `data-frame-persist-query-url-value=`: the initial URL of the frame, if
 *   the `src` attribute is not present.
 *
 * - `data-frame-persist-query-add-params-value=`: List of parameters to add on
 *   the next frame load, separated by `&`.
 *
 * - `data-frame-persist-query-remove-params-value=`: List of parameters to drop
 *   on the next frame load, separated by `&`.
 *
 * - `data-frame-persist-query-replace-params-value=`: List of parameters to
 *   replace on the next frame load, separated by `&`.
 *
 * This works by defining a custom delegate in the turbo-frame element that
 * changes the URL if necessary and forwards to the original delegate.
 *
 */

const debug = window.lcas_debug?.includes('frame-persist') // ?debug[frame-persist]=1

export default class FramePersistQueryController extends ApplicationController {

  static values = {
    params: String,
    url: String,
    addParams: String,
    replaceParams: String,
    removeParams: String
  }

  connect(){
    super.connect()
    this.delegate = this.element.delegate
    this.src = this.element.src || this.urlValue
    let controller = this
    this.element.delegate = new Proxy(this.delegate, {
      get(target, prop, receiver) {
        const value = Reflect.get(target, prop, receiver)

        if (prop == 'sourceURLChanged') {
          return controller.sourceURLChanged.bind(controller)
        }

        // For methods, bind the correct receiver (target) to maintain context
        // fix error:  Uncaught (in promise) TypeError: Receiver must be an instance of class FrameController
        // for this.#ignoringChangesToAttribute in set sourceURL within FrameController
        if (typeof value === 'function') {
          return value.bind(target)
        }

        return value
      }
    })

    // Save current changes in the src before page refresh
    this.addAction('beforeunload@window', 'onBeforeUnload')
  }

  disconnect(){
    this.element.delegate = this.delegate
    super.disconnect()
  }

  _extractSearch(url) {
    return url.replace(/^[^\?]*\?/, '') // eslint-disable-line no-useless-escape
  }

  _replaceSearch(url, newSearch) {
    const bare = url.replace(/\?.*$/, '')
    if (newSearch && newSearch[0] == '?') {
      return bare + newSearch
    } else if (newSearch) {
      return bare + '?' + newSearch
    } else {
      return bare
    }
  }

  handleSrcAttribute(){
    if (debug) console.log('handleSrcAttribute old: %s', this.src)
    if (debug) console.log('handleSrcAttribute new: %s', this.element.src)

    let updated = false

    if (this.src) {
      const oldParams = new URLSearchParams(this._extractSearch(this.src))
      let newParams = new URLSearchParams(this._extractSearch(this.element.src || ''))

      for (let param of this.paramsValue.split(/\s+/)) {
        if (!oldParams.has(param) || newParams.has(param)) continue

        for (let val of oldParams.getAll(param)) {
          newParams.append(param, val)
          updated = true
        }
      }

      for (let entry of new URLSearchParams(this.addParamsValue)) {
        newParams.append(entry[0], entry[1])
        updated = true
      }

      const replacedParams = new URLSearchParams(this.replaceParamsValue)
      let candidateNewParams = new URLSearchParams()
      let removedParams = new URLSearchParams(this.removeParamsValue)
      let removed = false
      for (let newEntry of newParams.entries()) {
        let foundInRemoveList = false
        for (let removeEntry of removedParams.entries()) {
          if (newEntry[0] == removeEntry[0] && newEntry[1] == removeEntry[1]) {
            foundInRemoveList = true
            removed = true
            updated = true
            break
          }
        }
        if (foundInRemoveList) continue
        for (let replaceEntry of replacedParams.entries()) {
          if (newEntry[0] == replaceEntry[0]) {
            foundInRemoveList = true
            removed = true
            updated = true
            break
          }
        }
        if (!foundInRemoveList) {
          candidateNewParams.append(newEntry[0], newEntry[1])
        }
      }
      if (removed) {
        newParams = candidateNewParams
      }

      for (let entry of replacedParams) {
        newParams.append(entry[0], entry[1])
        updated = true
      }

      if (updated) {
        this.src = this._replaceSearch(this.element.src, newParams.toString())
        if (this.src != this.element.src) {
          console.log('[frame-persist-query] #%s change url %s -> %s', this.element.id, this.element.src, this.src)
          this.element.src = this.src
          if (debug) console.log('handleSrcAttribute changed: %s', this.src)
        }
        return true
      }
    }

    this.src = this.element.src
    return false
  }

  sourceURLChanged(){
    this.handleSrcAttribute()
    return this.delegate.sourceURLChanged()
  }

  onBeforeUnload(e) {
    this.handleSrcAttribute()
  }

}
